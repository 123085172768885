import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Sell from "./components/Sell";
import "./App.scss";
import 'bootstrap/dist/css/bootstrap.css';
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import Charter from "./components/Charter";
import { Helmet } from "react-helmet";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Helmet>
          <title>Buy a Yacht | Luxury Yachts For Sale | Nest Yachts</title>
        </Helmet>
        <Routes>
          <Route path="/" element={<Home frontPageJson={this.props.frontPageJson}/>} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Charter" element={<Charter />} />
          <Route path="/Sell" element={<Sell />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsOfUse" element={<TermsOfUse />} />
        </Routes>
        <Footer />
      </div>
    );
  }
}

export default App;