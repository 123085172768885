import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Facebook, Instagram, Twitter, Youtube } from 'react-bootstrap-icons';
import "./Footer.scss";
import { Link } from "react-router-dom";
import { FRONTEND } from "../constants";

class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <Container>
                <Row className="pt-3 pb-4">
                    <Col className="footer-br">
                        <div className="text-end">Licensed Yacht Broker</div>
                    </Col>
                    <Col>
                        <div>Facility # 7129673</div>
                    </Col>
                </Row>
                <Row className="pb-4">
                    <img src={`${FRONTEND}footer-logo.svg`} alt="Nest Yachts Logo2" height="60px" width="auto" className="m-auto"/>
                </Row>
                <Row className="pb-4">
                    <div className="text-center">Yacht Disclaimer</div>
                    <p className="text-center">The luxury motor yacht Fine Print is displayed on this page merely for informational purposes and she is not necessarily available for yacht charter or for sale, nor is she represented or marketed in anyway by NestYachts. This document is not contractual. The yacht particulars displayed in the results above are displayed in good faith and whilst believed to be correct are not guaranteed, please check with your yacht charter broker. NestYachts does not warrant or assume any legal liability or responsibility for the accuracy, completeness, or usefulness of any information and/or images displayed as they may not be current. All boat information is subject to change without prior notice and is without warranty.</p>
                </Row>
                <Row className="pb-4 tc-mob">
                    <Col className="footer-br" lg={6}>
                        <div className="te">505 Park Avenue New York NY 10022</div>
                    </Col>
                    <Col lg={6}>
                        <div>108 Main Street Sag Harbor NY 11963</div>
                    </Col>
                </Row>
                <Container className="w-100 footer-bottom">
                    <Row className="pb-4 text-center mx-auto" style={{maxWidth: 600}}>
                        <Col md={3}><Link to="/TermsOfUse" title="Terms Of Use">Terms Of Use</Link></Col>
                        <Col md={3}><Link to="/PrivacyPolicy" title="Privacy Policy">Privacy Policy</Link></Col>
                        <Col md={3}><Link to="/Contact" title="Contact Us">Contact</Link></Col>
                        <Col md={3}><a href="https://www.nestseekers.com" title="Nest Seekers Website">Nestseekers.com</a></Col>
                    </Row>
                    <Row className="pb-4 text-center mx-auto" style={{maxWidth: 400}}>
                        <Col xs><a href="https://facebook.com/nestseekers" title="Nest Seekers Facebook"><Facebook/></a></Col>
                        <Col xs><a href="https://instagram.com/nestseekers" title="Nest Seekers Instagram"><Instagram/></a></Col>
                        <Col xs><a href="https://youtube.com/user/NestSeekersdotcom" title="Nest Seekers Youtube"><Youtube/></a></Col>
                        <Col xs><a href="https://twitter.com/nestseekers" title="Nest Seekers Twitter"><Twitter/></a></Col>
                    </Row>
                </Container>
            </Container>
            </div>
        )
    }
}

export default Footer;