import React from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { FRONTEND } from "../constants";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import "./Sell.scss";

export default class Sell extends React.Component {

    render() {
        return (
            <div className="sell">
                <Helmet>
                    <title>Sell Your Yacht | Nest Yachts</title>
                </Helmet>
                <div className="page-banner d-flex justify-content-center align-items-center" style={{
                    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), url('${FRONTEND}sell-banner.jpg')`
                }}>
                    <h1>Sell A Yacht</h1>
                </div>
                <div className="sell-about p-3 my-4">
                    <Container>
                        <Row>
                            <Col className="py-3">
                                <p>Nest Seekers International is first and foremost a creative agency. With decades of experience in conceptualizing, branding and marketing some of the worlds most desirable development projects and estates, Nest is an expert at branding and marketing any high end asset for its best market positioning in order to achieve the highest price possible in the shortest amount of time. Its marketing distribution platform includes a website that enjoys nearly a million unique visitors per year, massive verified social media networks with hundreds of thousands of fans and followers, search engine optimization expert campaign management, invaluable retail exposure and much more.</p>
                            </Col>
                            <Col sm={4} className="text-center">
                                <img src={`${FRONTEND}sell-img-1.jpg`} alt="Yacht Interior" height="200"/>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="sell-marketing px-3 py-5 my-4">
                    <Container>
                        <Col>
                            <Row className="py-3">
                                <p>Merchandizing and positioning is first step. Expert photography and videography including drone footage all carefully merchandized, edited and produced at the highest level. Research and copy to follow and then roll out of a strategic marketing campaign across its global distribution network. When suitable a press release and influencers campaigns for strategic media exposure.</p>
                            </Row>
                            <Row className="py-3">
                                <Col sm><img src={`${FRONTEND}sell-marketing-1.jpg`} alt="Sell Marketing Camera" height="200"/></Col>
                                <Col sm><img src={`${FRONTEND}sell-marketing-2.jpg`} alt="Sell Marketing Computer" height="200"/></Col>
                                <Col sm><img src={`${FRONTEND}sell-marketing-3.jpg`} alt="Yacht Interior Drone" height="200"/></Col>
                            </Row>
                            <Row className="py-3">
                                <p>Nest has established a fan base of millions of followers in various form and continues to expand its base through email marketing campaigns, registered users and customers and ongoing brand building campaign across its media projects and global brokerage networks.</p>
                            </Row>
                        </Col>
                    </Container>
                </div>
                <div className="sell-title p-2">
                    <Container className="p-2 text-center">
                        <h2>SELL YOUR VESSEL WITH NEST</h2>
                    </Container>
                </div>
                <div className="sell-contact p-3 mt-4">
                    <Container className="contact-us__inner py-5 px-2 text-center">
                        <h2 className="py-3"> INQUIRY 1800-212-1774 </h2>
                        <ContactForm contactFormType="sell"/>
                    </Container>
                </div>
            </div>
        )
    }
}