import React from "react";
import { Container } from "react-bootstrap";
import "./TermsOfUse.scss";
import { Helmet } from "react-helmet";

export default class TermsOfUse extends React.Component {

    render() {
        return (
            <div className="terms-of-use">
                <Helmet>
                    <title>Terms and Conditions of Use</title>
                </Helmet>
                <Container className="py-5 px-2">
                    <h3>Terms and Conditions of Use</h3>
                    <p>
                        Your use of the Site is subject to the Terms and Conditions of Use, which include the Privacy Policy, (“Terms”). You must review these Terms and agree to these Terms before you proceed. If you do not agree to these Terms, please do not access or use the Site.
                        <br/><br/>
                        <br/><br/>
                        1.  These terms constitute a binding contract between Nest Yachts LLC, Nest Seekers LLC, its owners, predecessors, affiliates, officers, directors, employees, agents, contractors, investors, successors and/or assigns (“Nest Seekers”, “us”, or “we”) and you. These Terms comprise the entire agreement between Nest Seekers and you with respect to the use of the Site and supersede all contemporaneous and prior agreements concerning the subject matter contained herein. The clauses herein are severable in that if a court of competent jurisdiction holds that any part of these terms is invalid or unenforceable the remainder of these Terms remains in full force and effect.<br/><br/>
                        2.  These Terms, the Privacy Policy and any other agreement or clause that is incorporated by reference herein can be updated, changed or modified by Nest Seekers at any time or for any reason without notice to you. We are not liable for any such updates, modifications, suspensions, discontinuance or removal of any clause or condition mentioned herein, and such action does not affect the contractual relationship established by these Terms. The most recent version of the Terms can always be accessed by the “Terms and Conditions of Use” link on the homepage of the Site. We encourage you to regularly check for updates and/or changes and to periodically review the Terms.<br/><br/>
                        3.  By entering this site you hereby acknowledge and agree that this website will only be construed and evaluated according to United States law. If you use this site from other locations you are responsible for compliance with any and all applicable local laws. We make no representations that the materials contained within this site are appropriate for locations outside the United States.<br/><br/>
                        4.  You represent that you are a prospective purchaser, seller, lessee or lessor of real property in the geographic areas of Nest Seekers licensed operation with a bona fide interest in the purchase, sale or lease of such real property.<br/><br/>
                        5.  You must register your use and/or intended use of the Site by creating an account with a user name and password that will not be used by or shared with anyone else. Your account is non-transferrable and non-assignable. You must be eighteen (18) years of age or older to register for an account.<br/><br/>
                        6.  You must provide accurate and correct information, and you must maintain the currency of your information. Nest Seekers is entitled to rely on the accuracy, correctness and currency of the information you provide. By registering for an account, you consent to be contacted by Nest Seekers , and to receive personalized emails, telephone calls, and/or faxes from us. The Site and all related transactions are intended to be accessed and conducted electronically. If we are ever required to communicate or transact with you non-electronically, for legal or other reasons, we reserve the right to charge you increased fees.<br/><br/>
                        7.  We will not give, sell or otherwise transfer your address or Account information to any other party for the purposes of initiating, or enabling other to initiate, electronic mail messages except as authorized by law or appropriate personnel or policies. Anyone who engages in the aforementioned activity may be found in violation of Federal Law.<br/><br/>
                        8.  Notices from Nest Seekers, including legal notices, will be sent via electronic mail to the address associated with your account. You will be considered to have received a notice when sent by Nest Seekers to your email address, whether or not it was received by you. Any notices that you wish to send to us must be sent by mail to Nest Seekers LLC, Eastside – Corporate, 505 Park Avenue, New York, NY 10022<br/><br/>
                        9.  By agreeing to these terms, you acknowledge that you are entering into a lawful broker-consumer relationship between us and you, albeit not an exclusive one, as may be defined under state law. You represent that you have not signed any agreement with any other real estate agent, broker, salesperson, or similar state licensed real estate professional (“Real Estate Agent”) that would prevent you from using Nest Seekers as your agent, nor do you have any contract agreement with a third party that would hinder or interfere with our relationship. You agree to notify us immediately in the event that you enter into a listing agreement, or any other agreement or contract with a Real Estate Agent.<br/><br/>
                        10.  In our capacity as your broker, you authorize us to review and provide you with copies of various documents related to any potential yachts sales, boat sales and real estate transactions, such as but not limited to home inspection reports. You agree that we may represent other prospective buyers interested in the same properties as you are interested in, and that may meet your purchase criteria. You also agree not to contact the owner, seller, and/or lessee of any property using information gained through the Site. You represent that you will not attempt to enter the property or speak with an owner, seller, and/or lessee without an appointment arranged by Nest Seekers.<br/><br/>
                        11.  The Site is for personal use only. You may not reproduce, copy, sell, repost to other sites or otherwise exploit the Site or any part thereof, or access or use the Site for any commercial purposes. If you are a Real Estate Agent and wish to access the Site for your customers, you must first enter into a co-brokerage agreement with Nest Seekers. No person, including without any Real Estate Agent, may make any commercial use of any content of this Site, including without limitation marketing, advertising or copying of any listings on the Site, or contacting or engaging in any sort of outreach to sellers or owners of any properties listed on the Site. You agree that we have the right to terminate or deny access to any Real Estate Agent or any person as we may deem appropriate.<br/><br/>
                        12.  The Registrant acknowledges each other RLS Broker's ownership of, and the validity of their respective copyright in, the Exclusive Listings that are transmitted over the RLS.<br/><br/>
                        13.  Nest Seekers authorizes the Real Estate Board of New York (“REBNY”) and/or REBNY Listing Service (“RLS”) brokers and their representative to access the Site for the purposes of verifying compliance with the provisions of these Terms, the Co-Brokerage Agreement between Nest Seekers and REBNY, and any other applicable RLS rules or policies or any other multiple listing system or real estate board that Nest Seekers subscribes to.<br/><br/>
                        14.  You may not take any action which might impose a significant burden (as determined by us) on the Site“s infrastructure or computer systems, or otherwise interfere with the ordinary operation of the Site. You are prohibited from accessing or using the Site in any way that is not in compliance with any applicable local, state, national or international law, agency rules or regulations, contract, intellectual property rights or constitutes the commission of a tort, other than in full compliance with these terms. Access or use the Site in any way that is not in compliance with any applicable local, state, national or international law (including export laws), contracts, intellectual property rights or constitutes the commission of a tort, or for any purpose that is harmful or unintended (by us), or other than in full compliance with these Terms. Nest Seekers reserves the right to make such determinations regarding the conduct of any individual.<br/><br/>
                        15.  You are prohibited to use any content in violation of any applicable laws, rules or regulations, including without limitation the rules of Real Estate Board of the City of New York, the New York State Department of State, the New York State Department of Banking, or any listing agency. You are forbidden to access, tamper with, or use services or areas of the Site that you are not authorized to access. You may not alter or tamper with information on or obtained from the Site.<br/><br/>
                        16.  You may not use any robot, spider, scraper or other automated means or interface not provided by us to access the Site or extract data or gather or use information, such as email addresses, available from the Site or transmit any unsolicited advertising, “junk mail,” “spam,” or “chain letters.” You may not frame any part of the Site, or link to the Site, or otherwise make it look like you have a relationship to us or that we have endorsed you or your Content for any purpose. You may not impersonate or misrepresent your affiliation with any person or entity. You may not reverse engineer any licensed software, application, games or any other aspect of the Site or do anything that might discover source code, or bypass or circumvent measures employed to prevent or limit access to any area, content or code of the Site. You may not send to or otherwise impact us or the Site (or anything or anyone else) with harmful, illegal, deceptive or disruptive code such as a virus, “spyware,” “adware” or other code that could adversely impact the Site or any recipient.<br/><br/>
                        17.  As set forth in the Privacy section of this Site, you are bound by the intellectual property laws that protect the Site. Nest Seekers reserves all rights in the content of its website, including software, not expressly granted in this document. All rights in all material and content including, but not limited to, text, images, web pages, sounds, software (including code, interface, and website structure), and video, and the look and feel, design, and compilation thereof on the website are owned or licensed by us.<br/><br/>
                        18.  You may not reproduce distribute, modify, publicly display, or prepare derivative works of any content, including software, without prior, express, written permission from Nest Seekers. Nest Seekers grants you a limited, revocable, non-transferable, non-exclusive, non-sublicensable license to privately display the content of this Site on your computer and to download and print a single copy thereof, subject to the restrictions set forth herein.<br/><br/>
                        19.  You shall not copy, redistribute, or retransmit any of the Site’s information or data, except in connection with your consideration of the purchase, sale or lease of an individual exclusive property.<br/><br/>
                        20.  These terms shall remain in full force and effect until terminated in accordance with this Section. Nest Seekers may, at any time, terminate your access to the Site and/or your Account for any reason, consistent with local, state and federal, and/or relevant agency laws and/or rules. We reserve the right to use our discretion to determine whether or not you have violated any clause herein or have otherwise engaged in any activities that may harm or damage the reputation, rights, person, or property of Nest Seekers, our users, or any other person. Nest Seekers will not be liable to you or any third party for any termination of your Account or your access to the Site.<br/><br/>
                        21.  Nest Seekers, its owners, affiliates, officers, directors, employees, agents, contractors, content or service providers (including listing service providers), investors, successors and assigns specifically disclaim any and all warranties, express or implied by statute or otherwise, including but not limited to: (1) any warranties concerning the availability, accuracy, usefulness, or content of or uninterrupted access to information, products or services and (2) any warranties of title, non-infringement, merchantability or fitness for a particular purpose. This disclaimer of liability applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft, or destruction or unauthorized access or alteration of or use of record in connection with the use or operation of the Site, whether for breach of contract, tort, negligence or any other cause of action.<br/><br/>
                        22.  All information, content and service available on the Site are for information purposes, and are not intended to be comprehensive, and may not be accurate, exact or up to date. Such information, content and service may be updated, changed, withdrawn and modified at any time and without notice. Square footage and dimension information is approximate. Any representations of furnishings or decorations are for illustrative effects only. Any listing may be sold or rented at any time without notice. Nest Seekers is not responsible and does not accept any liability for omissions or inaccuracies on the Site. If you rely on any information, content or service on the Site, you expressly agree that you do so at your own risk. You agree that you are solely responsible for any damage or loss you may incur as a result of you reliance or usage of any information, content or service on the Site. If you are dissatisfied with any of the information, content, and/or service of this site, or with any of these terms, your sole remedy is to discontinue your use of the Site.<br/><br/>
                        23.  You agree to indemnify, defend and hold harmless Nest Seekers from any and all claims or damage, including attorney“s fees, arising out of or related to your use of or connection to the Site, your violation of these Terms or your violation of the any rights of another. To the extent permitted by applicable law, Nest Seekers shall not be liable to you or any other third party for any direct, indirect, incidental, special, punitive or consequential damages arising out of your access to or use of or connection to the Site. Nest Seekers shall not be liable to you or any other third party for your inability to gain access to or use the Site. Even if Nest Seekers has been advised or notified of damages or potential damages, Nest Seekers shall not be liable for any of the foregoing or for any breach of any warranty.<br/><br/>
                        24.  This site may contain links to other websites. These links are provided as a convenience to you and as an additional avenue of access to the information contained therein. We have not necessarily reviewed all the information on those other sites and are not responsible for the content of those or any other sites or any products or services that may be offered through those or any other sites. Inclusion of links to other sites should not be viewed as an endorsement of the content of linked sites. Different terms and conditions may apply to your use of any linked sites. We are not responsible for any losses, damages or other liabilities incurred as a result of your use of any linked sites.<br/><br/>
                        25.  These Terms are for the sole benefit of Nest Seekers. No other person, including any user of the site shall have the right to assert a claim under these Terms. These Terms and all performances and claims between us are governed by the laws of the State of New York and the United States of America. You and Nest Seekers submit to the personal jurisdiction and venue of the State Courts and Federal Courts located within the City of New York. The parties hereto waive the right to trial by jury.<br/><br/>
                        26.  Any action concerning any dispute with respect to the Site must be commenced within two years after the cause of the dispute arises, or the action is barred. The failure of Nest Seekers to exercise or enforce any right or provision of these Terms, including any failure to act with respect to a breach, will not constitute a waiver of such right or provision or the right to act with respect to subsequent or similar breaches.<br/><br/>
                        27.  It is our policy to terminate any Account or user for infringement of intellectual property rights, including trademark rights and copyrights. Nest Seekers may disclose any of your information or data related to a complaint of copyright infringement if we believe in our sole discretion that such action is necessary or useful to address an alleged infringement.<br/><br/>
                        28.  If you believe that your work has been copied in a way that constitutes copyright infringement, please forward the following information to the Copyright Agent named below:<br/><br/>
                        a.     Your address, telephone number, and email address;<br/><br/>
                        b.     A description of the copyrighted work that you claim has been infringed;<br/><br/>
                        c.     A description of the alleged infringing activity and where the alleged infringing material is located;<br/><br/>
                        d.     A statement by you that you have a good faith belief that the disputed use is not authorized by you, the copyright owner, its agent, or the law;<br/><br/>
                        e.     An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; and<br/><br/>
                        f.      A statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.<br/><br/>
                        <br/><br/>
                        Copyright Agent:
                        <br/><br/>
                        Joel Weiss, Esq.<br/><br/>
                        Weiss & Arons LLP<br/><br/>
                        <br/><br/>
                        1540 Route 202, Suite 8 Pomona, NY 10970
                        <br/><br/>
                        <br/><br/>
                        29.  This site includes certain trademarks, logos and service marks owned by Nest Seekers. Nothing on this website shall be construed as granting, by implication, estoppel, or otherwise, any license or right to use any trademark, logo or service mark displayed on the website without the owner's prior written permission, except as otherwise described herein. We reserve all rights not expressly granted in and to the website and its content. This website and all of its content, including but not limited to text, design, graphics, interfaces and code, and the selection and arrangement thereof, is protected as a compilation under the copyright laws of the United States and other countries. You are prohibited from using any trademarks or service marks in any manner without the express written permission of Nest Seekers. You further agree that you will not alter or remove any copyright, trademark or other legal notice from any content on the Site.<br/><br/>
                        30.  These terms constitute the final and binding agreement between the parties regarding the subject matter hereof, and supersedes all prior agreement, oral or written. The failure or delay of Nest Seekers to insist upon strict performance of any terms, conditions or covenants herein shall not be deemed a waiver of any rights or remedies that Nest Seekers may have, nor shall it be deemed a waiver of any subsequent breach or default in any of the terms, conditions, covenants herein.<br/><br/>
                    </p>
                </Container>
            </div>
        )
    }
}