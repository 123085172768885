import React from "react";
import { Col } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
// import { Row } from "reactstrap";
import { FRONTEND } from "../constants";
import "./Charter.scss";
import { Helmet } from "react-helmet";
import ContactForm from "./ContactForm";

export default class Charter extends React.Component {

    render() {
        return (
            <div className="charter">
                <Helmet>
                <title>Charter a Yacht | Luxury Yachts for Charter | Nest Yachts</title>
                </Helmet>
                <div className="page-banner d-flex justify-content-center align-items-center" style={{
                    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), url('${FRONTEND}charter-banner.jpg')`
                }}>
                    <h1>Charter</h1>
                    <a href="https://www.nestseekers.com/yachts-for-charter" target="_blank">Explore</a>
                </div>
                <div className="charter-explore px-3 pt-5 mt-4">
                    <Container className="about-inner d-flex justify-content-center text-center">
                        <Col>
                            <h2>EXPLORE THE WORLD IN STYLE</h2>
                            <p>
                                Whether you are looking for a few days of fun and entertainment in the Hamptons, from Sag Harbor to Montauk or across New England or weeks long Five Star experience in the Mediterranean. From Ibiza across the Balearic, the French Riviera, Amalfi coast or the Greek Islands, Nest Charters can help you plan your dream vacation staffed with Captains and highest level of service for your lifetime memories and magic of the seas without ownership of a vessel. High season in the North East and the Med begin from May to September and throughout the year in the Sunshine state and the Tropics.
                            </p>
                        </Col>
                    </Container>
                </div>
                {/* <div className="charter-find px-3 py-5 mt-4">
                    <Container className="about-inner d-flex justify-content-center text-center">
                        <Col>
                            <Row className="py-4">
                                <h2 className="pb-0 mb-0">FIND YOUR DREAM YACHT</h2>
                                <p>
                                    Coming soon.
                                </p>
                            </Row>
                            <Row className="py-3">
                                <Col sm><img src={`${FRONTEND}charter-yachts.jpg`} alt="Charter Yachts" height="200"/><h3>Yachts</h3></Col>
                                <Col sm><img src={`${FRONTEND}charter-superyachts.jpg`} alt="Charter Super Yachts" height="200"/><h3>Super Yachts</h3></Col>
                                <Col sm><img src={`${FRONTEND}charter-dayyachts.jpg`} alt="Charter Day Yachts" height="200"/><h3>Day Yachts</h3></Col>
                            </Row>
                        </Col>
                    </Container>
                </div> */}
                <div className="charter-video">
                    <Container>
                        <iframe src="https://player.vimeo.com/video/747657800?h=3a49767616&background=1" title="charter video" frameBorder="0" allowFullScreen=""></iframe>
                    </Container>
                </div>
                <div className="charter-contact p-3">
                    <Container className="contact-us__inner py-5 px-2 text-center">
                        <h2 className="py-3"> INQUIRY 1800-212-1774 </h2>
                        <ContactForm contactFormType="charter"/>
                    </Container>
                </div>
            </div>
        )
    }
}