import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap'
import { FRONTEND } from "../constants";
import "./Header.scss";

class Header extends React.Component {
    render() {
        return (
            <Navbar collapseOnSelect className="header" bg="black" expand="lg" variant="dark">
                <Container>
                    <LinkContainer to="/">
                        <Navbar.Brand>
                            <img
                                src={`${FRONTEND}nav-logo.svg`}
                                alt="Nest Yachts logo"
                                height="40px"
                            />

                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <LinkContainer to="/"><Nav.Link>Home</Nav.Link></LinkContainer>
                            <LinkContainer to="/About" title="About Us"><Nav.Link>About</Nav.Link></LinkContainer>
                            <LinkContainer to="/Sell" title="Sell Your Yacht"><Nav.Link>Sell</Nav.Link></LinkContainer>
                            <Nav.Link href="https://www.nestseekers.com/yachts-for-sale" title="Yachts For Sale">Buy</Nav.Link>
                            <Nav.Link href="https://www.nestseekers.com/yachts-for-charter" title="Yachts For Charter">Charter</Nav.Link>
                            {/* <Nav.Link href="/Charter" title="Yachts For Charter">Charter</Nav.Link> */}
                            <LinkContainer to="/Contact" title="Contact Us"><Nav.Link>Contact</Nav.Link></LinkContainer>
                            <Nav.Link href="https://www.nestseekers.com/" title="Nest Seekers Website">Nestseekers.com</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default Header;