import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FRONTEND } from "../constants";
import "./Home.scss";
import _ from "lodash";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import "pure-react-carousel/dist/react-carousel.es.css";
import { Helmet } from "react-helmet";

let formatNumber;

if (_.isUndefined(Intl)) {
  formatNumber = _.toString;
} else {
  let nf = new Intl.NumberFormat("en-US");
  formatNumber = n => nf.format(n);
}

export default class Home extends React.Component {

    render() {
        const frontPageJson = JSON.parse(this.props.frontPageJson) || {}
        const properties = frontPageJson.properties || []
        const yachts = frontPageJson.yachts || []
        const isMobile = window.innerWidth <= 1000;
        return (
            <div>
                <Helmet>
                    <title>Buy a Yacht | Luxury Yachts For Sale | Nest Yachts</title>
                </Helmet>
                <div id="video-background">
                    <div className="video-wrapper">
                    <iframe src="https://player.vimeo.com/video/609300675?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" title="Nest Yachts Banner Video" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen=""></iframe>
                    </div>
                </div>
                <div className="nest-fleet p-4 my-5">
                    <Container>
                        <Row>
                            <Col md={3} className="p-4">
                                <h2 style={{maxWidth: "220px"}}><span style={{fontSize: "2.5rem"}}>NAVIGATE</span><br/>OUR FLEET</h2>
                                <p className="py-3">Search and explore our luxury yachts</p>
                                <a className="nbtn" href="https://nestseekers.com/yachts-for-sale" title="Explore Nest Yachts Portfolio">Explore</a>
                            </Col>
                            <Col md={9} className="p-4">
                                <CarouselProvider
                                    currentSlide={Math.floor((Math.random() * yachts.length) / 2)}
                                    naturalSlideWidth={50}
                                    naturalSlideHeight={50}
                                    totalSlides={yachts.length}
                                    visibleSlides={isMobile ? 1 : 3}
                                    isPlaying
                                    infinite
                                    interval={6300}
                                >
                                    <Slider>
                                        {yachts.map((p, i) => 
                                            <Slide key={`y-${i}`} index={i}>
                                                <div className="c-slide h-100 w-100 p-3">
                                                    <a href={`https://nestseekers.com${p.url}`} title={p.vessel_name}>
                                                    <div className="c-image" style={{backgroundImage: `url('${p.thumbs.primary || p.thumbs.large}')`}}>
                                                    </div>
                                                    <div className="c-info p-2">
                                                        {p.vessel_name ? <div className="c-vname">{p.vessel_name}</div> : null} {p.price ? <div className="">{`$${formatNumber(p.price)}`}</div> : null}
                                                    </div>
                                                    </a>
                                                </div>
                                            </Slide>
                                        )}
                                    </Slider>
                                    <ButtonBack><ChevronLeft size={50}/></ButtonBack>
                                    <ButtonNext><ChevronRight size={50}/></ButtonNext>
                                </CarouselProvider>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="nest-quality py-4 px-2 my-5">
                    <Container>
                        <Row>
                            <Col className="p-4">
                                <CarouselProvider
                                        currentSlide={Math.floor((Math.random() * properties.length)/2)}
                                        naturalSlideWidth={50}
                                        naturalSlideHeight={50}
                                        totalSlides={properties.length}
                                        visibleSlides={isMobile ? 1 : 3}
                                        isPlaying
                                        infinite
                                        interval={7800}
                                    >
                                        <Slider>
                                            {properties.map((p, i) => 
                                                <Slide key={`p-${i}`} index={i}>
                                                    <div className="c-slide h-100 w-100 p-3">
                                                        <a href={p.url} title={p.address}>
                                                            <div className="c-image" style={{backgroundImage: `url('${p.image.imgurl || ""}')`}}>

                                                            </div>
                                                        </a>
                                                    </div>
                                                </Slide>
                                            )}
                                        </Slider>
                                        <ButtonBack><ChevronLeft size={50}/></ButtonBack>
                                        <ButtonNext><ChevronRight size={50}/></ButtonNext>
                                    </CarouselProvider>
                            </Col>
                            <Col md={3} className="p-4">
                                <h2 style={{maxWidth: "220px"}}>NEST KNOWS QUALITY <br/> WEALTH <br/> AND BRAND</h2>
                                <p className="py-3">With over 20 years of experience and over $100 Billion in transactions and representations of some of the worlds most magnificent properties, the creator of Nest Seekers International has now entered the brand, marketing and sales of yachts and super yachts brokerage and representation.</p>
                                <a className="nbtn" href="https://nestseekers.com/Properties/UltraLuxury" title="Discover Nest Seekers Portfolio">Discover</a>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="home-sell-buy">
                    <div className="home-sell-container">
                        <div className="home-sell" style={{backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url('${FRONTEND}home-sell.jpg')`}}>
                            <Link to="/Sell" title="Sell Your Yacht">Sell</Link>
                        </div>
                    </div>
                    <div className="home-buy-container">
                        <div className="home-buy" style={{backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url('${FRONTEND}home-buy.jpg')`}}>
                            <a href="https://www.nestseekers.com/yachts-for-sale" title="Yachts For Sale">Buy</a>
                        </div>
                    </div>
                </div>
                <div className="home-charter py-4 px-2 my-5">
                    <Container className="home-charter-inner">
                        <h2>CHARTER</h2>
                        <p className="py-4 px-2">
                            Whether you are looking for a few days of fun and entertainment in the Hamptons, from Sag Harbor to Montauk or across New England or weeks long Five Star experience in the Mediterranean. From Ibiza across the Balearic, the French Riviera, Amalfi coast or the Greek Islands, Nest Charters can help you plan your dream vacation staffed with Captains and highest level of service for your lifetime memories and magic of the seas without ownership of a vessel. High season in the North East and the Med begin from May to September and throughout the year in the Sunshine state and the Tropics.
                        </p>
                        <a href="https://www.nestseekers.com/yachts-for-charter" target="_blank">Explore</a>
                    </Container>
                </div>
                <div className="home-charter-video">
                    <Container>
                        <iframe src="https://player.vimeo.com/video/747657546?h=233a3babdd&background=1" title="home charter video" frameBorder="0" allowFullScreen=""></iframe>
                    </Container>
                </div>
            </div>
        )
    }
}