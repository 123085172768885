import React from "react";
import Container from "react-bootstrap/esm/Container";
import { FRONTEND } from "../constants";
import "./About.scss";
import { Helmet } from "react-helmet";

export default class About extends React.Component {

    render() {
        return (
            <div className="about">
                <Helmet>
                    <title>About Nest Yachts</title>
                </Helmet>
                <div className="page-banner d-flex justify-content-center align-items-center" style={{
                    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), url('${FRONTEND}about-banner.jpg')`
                }}>
                    <h1>About</h1>
                </div>
                <div className="about-powerhouse">
                    <Container className="about-inner d-flex justify-content-center">
                        <div className="about-powerhouse-left">
                            <h2>A DYNAMIC POWERHOUSE</h2>
                            <p>
                                Over the past 20 years Nest Seekers International has established itself as one of the most followed brokerage brands in the world. With over 1500 agents and employees spread between 30 offices
                                and storefront in the world's most sought locations. Nest is a global brand and attracts
                                high net worth buyers and sellers from markets such as New York City, London,
                                The Hamptons, Miami, Palm beach, Los Angles, Newport Beach, Portugal, Spain, France, Italy and more.
                            </p>
                        </div>
                        <div className="about-powerhouse-right">
                            <img className="about-world" src={`${FRONTEND}about-world.jpg`} alt="regions-operated"/>
                            <img className="ceo" src={`${FRONTEND}ceo.png`} alt="ceo"/>
                            <p>
                                During these two decades, Nest Seekers has represented and or completed transactions in excess of over $100B across the various markets and asset class and has now emerging as a luxury brokerage for all major hard assets including yachts. Founded by industry veteran and visionary Eddie Shapiro the Nest Organization is also a creative agency, a production and media company with shows distributing on Netflix, Discovery/HBO, BBC and more.
                            </p>
                        </div>
                    </Container>
                </div>
                <div className="about-video">
                    <Container>
                        <iframe src="https://player.vimeo.com/video/616373565" title="about us video" frameBorder="0" allowFullScreen=""></iframe>
                        <p>The company’s distributions platform and media exposure is enormous and unique in the world of brokerage. As a result of its unique positioning and dynamic infrastructure, Nest has developed the most discerning clientele from around the world and have represented some of world's most spectacular homes. It is now committed to the same level of standard across its boating and yachting division.</p>
                    </Container>
                </div>
            </div>
        )
    }
}