import React from "react";
import { Container } from "react-bootstrap";
import "./PrivacyPolicy.scss";
import { Helmet } from "react-helmet";

export default class PrivacyPolicy extends React.Component {

    render() {
        return (
            <div className="privacy-policy">
                <Helmet>
                    <title>Privacy Policy</title>
                </Helmet>
                <Container className="py-5 px-2">
                    <h3>Privacy Policy for www.nestyachts.com and www.nestseekers.com</h3>
                    <br/><br/>
                    <p>
                        This website is operated by Nest Seekers LLC. All references to our, us, or we within this policy are deemed to refer to Nest Seekers LLC, its subsidiaries, affiliates, and/or associates, as appropriate. We understand and respect your concerns about privacy and security, and endeavor to protect your personal privacy, while providing a convenient and safe online experience. This Privacy Notice is incorporated into, and part of the Terms and Conditions of Use that govern your use of www.nestyachts.com and www.nestseekers.com (the Site) in general. We may need to update this Privacy Notice from time to time, and we reserve the right to do so at our sole discretion and without notice. We recommend that you check this page regularly to ensure that you have read the most recent version.
                        <br/><br/>
                        By providing your personal information to us, you signify your acceptance of our Privacy Notice and agree that we may collect, use, and disclose your personal information as described within this Notice. If you do not agree to this Notice, please do not provide your personal details to us. We will use your information only for the purposes set for the below.
                        <br/><br/>
                        <br/><br/>
                        <strong>Personal Information</strong>
                        <br/><br/>
                        Personal information is information about an identifiable individual, including name address, email address, telephone number, and other identifying information as defined by applicable law. It is your choice to provide us with personal information. When you register for an Account, you will be asked to provide us with certain personal information, such as name, address, email address, telephone number, information regarding the type, price, location and features of a specific or desired property, and/or other personal information. You may also voluntarily provide us personal information if you email us, inquire about a property, call us, and/or otherwise respond to a request from us for information.
                        <br/><br/>
                        We may use personal information collected from you to provide specific services that you request, as well as to provide additional services that may be of interest to you. We may also automatically collect certain information concerning your visit to the Site, such as you Internet Protocol address and browser information, which will allow us to track your use of the Site.
                        <br/><br/>
                        Subject to the terms of this Policy, you give us permission to use personal information in any manner we desire. Some examples of how we may use personal information include without limitation, to contact you, to identify your search preferences, and/or to respond to your comments and inquiries.
                        <br/><br/>
                        We may use a third party to help us gather and analyze information regarding your use of the Site. We may also consult with a third party in order to improve or enhance Site performance and/or to help us evaluate information. We may combine information that you provide or that we collect with other publicly available information, in order to maintain the accuracy of our records.
                        <br/><br/>
                        We reserve the right to retain any personally identifiable information reasonably necessary to appropriately analyze or document our business activities and for archive and retention purposes. We will store personal information for as long as is reasonably necessary for any reason described herein.
                        <br/><br/>
                        <br/><br/>
                        <strong>Disclosure of Personal Information</strong>
                        <br/><br/>
                        We will not sell personal information to unaffiliated third parties for commercial use. However, we may share personal information with third parties as set forth below:<br/><br/>
                        1.     One or more of our real estate brokers, agents, and/or affiliates in order to service you;<br/><br/>
                        2.     Our associates, partners, or related companies in the real estate industry, such as a real estate management company, if we believe it may interest or be of service to you;<br/><br/>
                        3.     Third parties who perform services for us or with whom we contract for the purposes described in this Privacy Policy. For example, we may use third parties to host the Site, conduct customer research, manage and analyze data, send emails, and/or print or send mailings. These third party service providers are not authorized by us to use or disclose your personal information for their own or any others' marketing purposes;<br/><br/>
                        4.     When Client provides (“refers”) a Lead to Localize that includes a telephone number, Client represents, covenants, and warrants that Client or a third party has obtained from the owner of the telephone number a valid prior express written consent to be called and texted by or on behalf of Client under all applicable telemarketing laws such as the Telephone Consumer Protection Act (“TCPA”)—such as a signature agreeing that “I voluntarily consent to receive text messages and calls from or on behalf of Client and its service providers at my telephone number, including messages and calls sent or initiated through automated means. I understand that these communications may include marketing and related content and that consent is not a condition of any purchase.” Client should consult with its own counsel about the exact requirements and language necessary for valid consent and agrees that the above language is not necessarily legally sufficient. If the owner of a telephone number or the customary user of a telephone number requests that calls or texts no longer be sent to the phone number, Client agrees to comply and to notify Localize immediately.<br/><br/>
                        5.     Owners and sponsors of listings on our Site or of other properties who may seek to sell or lease their properties through us;<br/><br/>
                        6.     A person or entity, including our affiliates, in the event of a sale, merger, consolidation, change in control, transfer of substantial assets, financing, reorganization, or liquidation whereby we transfer, sell, or assign to such third party information concerning your relationship with us, including without limitation, personally identifiable information that you provide and other information concerning your relationship with us;<br/><br/>
                        7.     Third parties who, in our reasonable judgment, are providing or seeking the information as your authorized or appointed legal agent; and<br/><br/>
                        8.     Law enforcement, governmental or regulatory agencies, or other third parties in order to comply with applicable law, or where we believe such action is necessary in order to comply with applicable law, or to detect, protect, or defend us and/or other third parties against error, negligence, breach of contract, theft, fraud, or other illegal or harmful activity, to comply with our audit and security requirements, or to audit compliance with our corporate policies, procedures, legal, or contractual obligations<br/><br/>
                        <br/><br/>
                        <strong>Cookies</strong>
                        <br/><br/>
                        Like many other websites, we and some of our service providers use "cookies" to help us gather and store information about visitors to our websites. Such information is then used to improve the content and offering of the Site and to personalize a user’s experience on the Site.
                        <br/><br/>
                        Cookies are text files containing small amounts of information which are downloaded to your device when you visit a website. Cookies are then sent back to the originating website on each subsequent visit, or to another website that recognizes that cookie. Cookies allow a website to recognize a user’s device, so a user can navigate between pages efficiently and will be shown online advertisements that are more relevant to the user’s interest. This is not an exhaustive list of uses of cookies. For more detailed information about cookies, please visit www.allaboutcookies.org. Most Web browsers are set up to accept cookies. You can, however, set your browser to refuse all cookies or to indicate when a cookie is being sent. In addition, cookies can be easily and safely deleted from your system and managed, in general. See your browser's help section for instructions. Should you choose to disable any cookies associated with the Site, you will not be able to take full advantage of all the Site’s features, but you should be able to navigate the Site without difficulty
                        <br/><br/>
                        <br/><br/>
                        <strong>Third Party Websites</strong>
                        <br/><br/>
                        The site may contain links, references, and content from other websites outside of our control. Please be aware that we have limited or no control over these websites and our Privacy Notice does not apply to these sites. We encourage you to read the Privacy Notice and terms and conditions of any linked, referenced, or interfacing websites you enter.
                        <br/><br/>
                        <br/><br/>
                        <strong>Security</strong>
                        <br/><br/>
                        We take security seriously and take precautions to keep your personal information secure. We have put in place appropriate physical, electronic, and managerial procedures to safeguard the information we collect. However, we have no control over the privacy of any communication while it is in transit to us. We therefore recommend that you not include confidential, proprietary, or sensitive information in any such communications.
                        <br/><br/>
                        In the unlikely event that we believe that the security of your personal information in our possession or control may have been compromised, we may seek to notify you of that development. If such a notification is appropriate, we will endeavor to do so as promptly as possible under the circumstances, and, to the extent we have your email address, we may notify you by email.
                        <br/><br/>
                        You are reminded that, in accordance with the Terms and Conditions for this website, which include this Privacy, you are responsible for maintaining the strict confidentiality of your account password, and you are responsible for any activity under your account and password. It is your sole responsibility to control the dissemination and use of your password, control access to and use of your account, and notify us when you wish to cancel your account. We will not be responsible or liable for any loss or damage arising from your failure to comply with this obligation.
                        <br/><br/>
                        <br/><br/>
                        <strong>Additional Privacy Protection for Children</strong>
                        <br/><br/>
                        We are, of course, concerned about the safety of children when they use the Internet. We understand that children may not fully comprehend all of the provisions of our Privacy Policy or make informed decisions about the choices that are made available to adult users of our website. We encourage parents and guardians to spend time with their children online and to be familiar with the sites their children visit. Our website is not directed to children under the age of 18, and we will never request personally identifiable information from anyone whom we know to be under the age of 18 without verifiable parental consent. If we become aware that a user is under the age of 18 and has registered without prior verifiable parental consent, we will remove his or her personally identifiable registration information from our files.
                        <br/><br/>
                        <br/><br/>
                        <strong>Unsubscribe, access, questions and further information</strong>
                        <br/><br/>
                        At your request and where the law requires us to do so, if you contact us using the unsubscribe details provided below we will confirm what personal information we hold about you, update your information, remove your information, fulfill specific opt-out requests (including requests where you previously opted in with respect to the use of your personal information), and/or correct any inaccuracies in such personal information.
                        <br/><br/>
                        If you would like to unsubscribe from Nest Seekers, as a registered user you can do so on the Email Preferences page, or you may do so by sending an email to info@nestseekers.com with a request to unsubscribe. Once your request has been processed, you will not receive anything further from us, unless you open a new account, inquire about a property, contact us or otherwise subsequently sign up to receive newsletters, emails or personalized online advertisements, you will not receive anything further from us.
                        <br/><br/>
                        If you have questions regarding our Privacy Policy, or if you wish to update the personal information you have provided to us, you may do so by emailing us at info@nestseekers.com.
                    </p>
                </Container>
            </div>
        )
    }
}