import React from "react";
import { Container } from "react-bootstrap";
import { FRONTEND } from "../constants";
import ContactForm from "./ContactForm";
import "./Contact.scss";
import { Helmet } from "react-helmet";

export default class Contact extends React.Component {

    render() {
        return (
            <div className="contact">
                <Helmet>
                    <title>Contact a Yacht Broker | Nest Yachts</title>
                </Helmet>
                <div className="page-banner d-flex justify-content-center align-items-center" style={{
                    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), url('${FRONTEND}contact-banner.jpg')`
                }}>
                    <h1>Contact Us</h1>
                </div>
                <div className="contact-us__form">
                    <Container className="contact-us__inner py-5 px-3 text-center">
                        <h2 className="pt-3"> INQUIRY 1800-212-1774 </h2>
                        <p className="py-3 px-2">Please complete the information below and we will respond to your inquiry as soon as possible. <br/>Your information will not be used for any other purposes.</p>
                        <ContactForm contactFormType="general"/>
                    </Container>
                </div>

            </div>
        )
    }
}