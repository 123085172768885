import React from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import axios from "axios";

import { API_URL } from "../constants";
import { Link } from "react-router-dom";



class ContactForm extends React.Component {
    state = {
      pk: 0,
      fname: "",
      lname: "",
      email: "",
      phone: "",
      newsletter: false,
      confirmed: false,
      message: "",
      submitted: false,
      submitting: false,
    };
  
    componentDidMount() {
      console.log(this.props)
    }
  
    onChange = e => {
      this.setState({ [e.target.name]: e.target.value });
    };
  
    createContact = e => {
      e.preventDefault();
      const {fname, lname, email, phone, message, newsletter} = this.state
      this.setState({
        submitting: true,
      })
      axios.post(API_URL, {
        fname, lname, email, phone, newsletter, message,
      }).then((resp) => {
        this.setState({
          submitted: true,
          submitting: false
        })
      }).catch(() => {
        this.setState({
          error: true,
          submitting: false
        })
      });
    };
  
    defaultIfEmpty = value => {
      return value === "" ? "" : value;
    };

    onChangToggle = e => {
      this.setState({ [e.target.name]: !this.state[e.target.name] });
    }
  
    render() {
      return (
        <Form onSubmit={this.createContact}>
          <FormGroup>
            {/* <Label for="fname">First Name:</Label> */}
            <Input
              type="text"
              name="fname"
              placeholder="First Name"
              onChange={this.onChange}
              value={this.defaultIfEmpty(this.state.fname)}
            />
          </FormGroup>
          <FormGroup>
            {/* <Label for="lname">Last Name:</Label> */}
            <Input
              type="text"
              name="lname"
              placeholder="Last Name"
              onChange={this.onChange}
              value={this.defaultIfEmpty(this.state.lname)}
            />
          </FormGroup>
          <FormGroup>
            {/* <Label for="email">Email:</Label> */}
            <Input
              type="email"
              name="email"
              placeholder="Email"
              onChange={this.onChange}
              value={this.defaultIfEmpty(this.state.email)}
            />
          </FormGroup>
          <FormGroup>
            {/* <Label for="phone">Phone:</Label> */}
            <Input
              type="text"
              name="phone"
              placeholder="Phone"
              onChange={this.onChange}
              value={this.defaultIfEmpty(this.state.phone)}
            />
          </FormGroup>
          <FormGroup>
            {/* <Label for="message">Message:</Label> */}
            <Input
              type="textarea"
              name="message"
              placeholder="Message"
              onChange={this.onChange}
              value={this.defaultIfEmpty(this.state.message)}
            />
          </FormGroup>
          {this.props.contactFormType === "general" &&
            <div>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="newsletter" onChange={this.onChangToggle}/> Please subscribe me to your <strong>Nest Yachts NEWSLETTER</strong>. I would like to be kept updated on the latest yachts for sale and charter, owner services and support, market information and ideas for itineraries and new destinations worldwide.
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="confirmed" onChange={this.onChangToggle} /> Please click here to confirm your consent to being contacted by Nest Yacht in regards to the interests and details you have indicated above. For further details please see our <Link to="/PrivacyPolicy" target="_blank">Privacy Policy</Link>.
                </Label>
              </FormGroup>
            </div>
          }
          {this.state.error &&
            <div className="contact-error"> There was an unexpected error, please try again later. </div>
          }
          {this.state.submitted ?
              <div>Thank you, somebody will contact you as soon as possible.</div>
            :
              this.props.contactFormType === "general" ?
                <Button disabled={!this.state.confirmed || this.state.submitting}>{this.state.submitting ? "Submitting..." : "Submit"}</Button>
              :
                <Button disabled={this.state.submitting}>{this.state.submitting ? "Submitting..." : "Submit"}</Button>
          }
        </Form>
      );
    }
  }
  
  export default ContactForm;